










































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';

import { objectIsEmpty } from '~/utils/common.js';
import { snowSports, footSports, bikeSports, sportsUniverses } from '~/utils/runConstants.js';

import ArrowNarrowRightSolid from '~/components/icons/ArrowNarrowRightSolid.vue';
import GlobalSearchInput from '~/components/inputs/GlobalSearchInput.vue';
import NavigationOutline from '~/components/icons/NavigationOutline.vue';
import GlobalSearchDestinations from '~/components/lists/GlobalSearchDestinations.vue';
import GlobalSearchRoutes from '~/components/lists/GlobalSearchRoutes.vue';
import GlobalSearchEvents from '~/components/lists/GlobalSearchEvents.vue';
import GlobalSearchProviders from '~/components/lists/GlobalSearchProviders.vue';
import GlobalSearchBlog from '~/components/lists/GlobalSearchBlog.vue';
import GlobalSearchSports from '~/components/lists/GlobalSearchSports.vue';
import GlobalSearchLocalisation from '~/components/lists/GlobalSearchLocalisation.vue';
import SearchOutline from '~/components/icons/SearchOutline.vue';
import LoaderPlaceholder from '~/components/icons/LoaderPlaceholder.vue';
import WarningOutline from '~/components/icons/WarningOutline.vue';

const TABS = {
  DESTINATION: 'destination',
  SPORT: 'sport',
};

@Component({
  components: {
    ArrowNarrowRightSolid,
    GlobalSearchInput,
    NavigationOutline,
    GlobalSearchDestinations,
    GlobalSearchRoutes,
    GlobalSearchEvents,
    GlobalSearchProviders,
    GlobalSearchBlog,
    GlobalSearchSports,
    GlobalSearchLocalisation,
    SearchOutline,
    LoaderPlaceholder,
    WarningOutline,
  },
  computed: {
    ...mapState('explorer', [
      'globalSearchTab',
      'destinationsResults',
      'routesResults',
      'eventsResults',
      'providersResults',
      'sports',
      'destinationSuggestions',
      'searchedLocs',
      'mappingOfSuggestedDestinations',
      'userCoords',
    ]),
    ...mapState('lang', [
      'locale',
    ]),
  },
})
export default class GlobalSearch extends Vue {
  @Prop(String) selectedLoc: '';
  @Prop(Object) selectedAreaBounds: Types.LeafletBounds;

  globalSearchTab: string;
  locale: string;
  destinationSuggestions: Types.SearchSuggestions;
  searchedLocs: Types.searchedLocs;
  mappingOfSuggestedDestinations: Types.mappingOfSuggested[];
  userCoords: Types.userCoords;
  sports: Types.SportItem[];

  searchText: string = '';

  allSubmittedSports: Array<Types.SportItem> = [];
  selectedDestination: any= {};

  specialContextDisplay: boolean = false;

  Tabs: Types.Dictionary<string> = TABS;
  isRequestingUserLoc: boolean = false;
  selectedBounds: Types.LeafletBounds = null;
  hasRefusedLoc: boolean = false;
  defaultEuropeBounds: Array<number[]> = [
    [-90, -180],
    [90, 180],
  ];

  /* Returns context of actual page, if in /explorer/ pages */
  get pageIsExplorer () {
    return this.$route?.name && this.$route.name.includes(`explorer___${this.locale}`);
  }

  /*
    Checks if actual page has no queries (will be used to handle preselected
    filters in URL)
  */
  get routeContainsNoQueries () {
    return objectIsEmpty(this.$route.query);
  }

  /*
    If user has selected AroundMe status, we update the style in the template
    and resets associated datas that will be useful for final submit
  */
  handleAroundMeText () {
    if (this.searchText === this.$t('globalSearch.aroundMeButton') && this.globalSearchTab === this.Tabs.DESTINATION) {
      this.searchText = '';
      this.selectedDestination = '';
    };
  }

  /*
    BEFORE SEARCHING BY TEXT, if user clicks on a country or a region, we are
    able to use reverse geoLoc to retrieve its bounds or coordinates to apply
    a search inside them.
    When operation succeeds, we display the Sports tab
  */
  async handleClickedSuggestion (val: { name: string, bounds: number[] }) {
    let location: {properties: {name: string, extent: number[]}} | null = null;
    if (val.bounds) {
      location = {
        properties: {
          name: val.name,
          extent: [
            val.bounds[0],
            val.bounds[3],
            val.bounds[2],
            val.bounds[1],
          ],
        },
      };
    } else {
      location = await this.$store.dispatch('explorer/getSearchedLocalisation', {
        text: val.name,
        lang: this.locale,
      });
    }
    this.searchText = val.name;
    this.goToSportTab(location);
  }

  /* Goes to the sport tab */
  goToSportTab (val: any) {
    this.selectedDestination = val;
    this.$store.commit('explorer/SET_GLOBAL_SEARCH_TAB', this.Tabs.SPORT);
  }

  async mounted () {
    // Similar method, we check if this.destinationSuggestions have been fetched
    // and if not, we do
    if (objectIsEmpty(this.destinationSuggestions)) {
      await this.$store.dispatch('explorer/getDestinationSuggestions', this.locale);
    }

    if (process.client) {
      // Freezes the DOM to avoid scrolls behind the modal
      document.body.classList.add('overflow-hidden');

      // Will close the modal if user hits Escape
      document.addEventListener('keydown', (e) => {
        if (e.key === "Escape") {
          this.$store.commit('SET_GLOBALSEARCH_DISPLAY', false);
        }
      });
    }

    // this.selectedLoc can be provided from layout, this.selectedAreaBounds
    // aswell. This case is valid when user mounts the page with an already done
    // search, that has informations in URL. We can populate datas with these
    // given props then.
    if (this.selectedLoc) {
      this.selectedDestination = this.selectedLoc;
      this.searchText = this.selectedLoc;
      this.selectedBounds = this.selectedAreaBounds;
    }
  }

  /*
    If user accepts to share location, we use this.$getLocation plugin, store
    values in store, then goes to sport tab. The modal will search elements
    around user location. If user loc fails to be retrieved, we will display an
    error hint
  */
  async askForLoc () {
    this.isRequestingUserLoc = true;
    if (!this.userCoords) {
      await this.$getLocation()
        .then((coordinates: Types.userCoords) => {
          this.$store.commit('explorer/SET_USER_COORDS', coordinates);
          this.goToSportViewAfterLoc();
        })
        .catch((err: string) => {
          if (err) {
            this.isRequestingUserLoc = false;
            this.hasRefusedLoc = true;
          }
        });
    } else {
      this.goToSportViewAfterLoc();
    }
  }

  /*
    Is triggered after chosing AroundMe feature, so the view updates to the
    sport tab
  */
  goToSportViewAfterLoc () {
    this.searchText = this.$t('globalSearch.aroundMeButton');
    this.selectedDestination = this.$t('globalSearch.aroundMeButton');
    this.$store.commit('explorer/SET_GLOBAL_SEARCH_TAB', this.Tabs.SPORT);
    this.isRequestingUserLoc = false;
    this.hasRefusedLoc = false;
  }

  /*
    Is triggered when user types in EditableText, to fetch datas from reverse
    geoLoc
  */
  callLocsApi () {
    if (this.searchText) {
      this.$store.dispatch('explorer/getSearchedLocalisations', {
        text: this.searchText,
        lang: this.locale,
      });
    }
  }

  /* Is triggered when user types in EditableText, to fetch datas from database */
  callAllResults () {
    if (this.searchText && this.searchText.length >= 3) {
      // Fetches destinations, routes, events and providers where title look like
      // submitted this.searchText
      this.$store.dispatch('explorer/searchDestinationsByText', { content: this.searchText, lang: this.locale });
      this.$store.dispatch('explorer/searchRoutesByText', { content: this.searchText, lang: this.locale });
      this.$store.dispatch('explorer/searchEventsByText', { content: this.searchText, lang: this.locale });
      this.$store.dispatch('explorer/searchProvidersByText', { content: this.searchText, lang: this.locale });

      // Creates a Map to store destinations suggestions by country, then regions
      const arrayOfDestinations = new Map();
      for (const countrycode in this.destinationSuggestions) {
        for (const state in this.destinationSuggestions[countrycode]) {
          for (const { destination } of this.destinationSuggestions[countrycode][state]) {
            arrayOfDestinations.set(destination.id, destination);
          }
        }
      }

      this.$store.commit('explorer/SET_MAPPING_OF_SUGGESTED_DESTINATIONS', arrayOfDestinations);
    }
  }

  /*
    When user clicks on search button, a lot of logic is applied here to search
    destinations, routes and events based on what user has selected :
    Around me, In a zone, In a specific marker, with or without sports.
  */
  handleSearch () {
    // Resets explorer bounds if a search was already done
    this.$store.commit('explorer/SET_EXPLORER_BOUNDS', {});

    // If user hasn't chose a destination, we will search for any dest, route
    // or event in Europe by default
    if (
      this.selectedDestination === '' ||
      objectIsEmpty(this.selectedDestination) ||
      !this.selectedDestination
    ) {
      this.selectedDestination = {};
    }

    // Search will be applied after chosing or not sports
    if (this.globalSearchTab === this.Tabs.SPORT) {
      let sportsQuerys = '';

      // If user has selected sports, we will create sport queries to construct
      // the final URL
      /* eslint-disable-next-line */
      this.allSubmittedSports.map((sport: Types.SportItem) => {
        sportsQuerys += `&sport=${sport.id}`;
      });

      let payload: string = '';

      // This first condition is if user has selected a value from the reverse
      // geoLoc suggestions. Based on the result's object structure, we construct
      // the localisation query with its name and its bounds
      if (this.selectedDestination?.properties) {
        this.$root.$emit('has-locname', this.selectedDestination?.properties.name);

        if (!this.selectedDestination?.properties.extent) {
          payload = `/explorer?locname=${this.selectedDestination?.properties.name}&long=${this.selectedDestination?.geometry.coordinates[0]}&lat=${this.selectedDestination?.geometry.coordinates[1]}`;
        } else {
          payload = `/explorer?locname=${this.selectedDestination?.properties.name}&longmin=${this.selectedDestination?.properties.extent[0]}&latmin=${this.selectedDestination?.properties.extent[3]}&longmax=${this.selectedDestination?.properties.extent[2]}&latmax=${this.selectedDestination?.properties.extent[1]}`;
        }
      } else if (this.selectedBounds && this.selectedDestination === this.$t('globalSearch.selectedArea')) {
        // This condition is valid if user is in explorer view, choses to use
        // "Select in this area", then attempts a new global search but keeping
        // the same zone. We will this.selectedBounds, props provided
        payload = `/explorer?loc=${this.selectedDestination}&longmin=${this.selectedBounds._southWest.lng}&latmin=${this.selectedBounds._southWest.lat}&longmax=${this.selectedBounds._northEast.lng}&latmax=${this.selectedBounds._northEast.lat}`;
      } else if (typeof this.selectedDestination === 'string' && this.selectedDestination !== this.$t('globalSearch.aroundMeButton')) {
        // This edge-case will search datas if user has already
        // searched, then tries a new one, but with the previous datas. We have
        // to do this case to prevent a search with empty datas
        payload = `/explorer?locname=${this.selectedDestination}&longmin=${this.$route.query.longmin}&latmin=${this.$route.query.latmin}&longmax=${this.$route.query.longmax}&latmax=${this.$route.query.latmax}`;
      } else if (this.selectedDestination === '' || objectIsEmpty(this.selectedDestination)) {
        // If user hasn't chosen a destination, we search in Europe by default
        this.$root.$emit('has-locname', '');

        payload = `/explorer?longmin=${this.defaultEuropeBounds[0][1]}&latmin=${this.defaultEuropeBounds[0][0]}&longmax=${this.defaultEuropeBounds[1][1]}&latmax=${this.defaultEuropeBounds[1][0]}`;
      } else {
        // The last case is the AroundMe selection.
        payload = `/explorer?aroundMe=true`;
      }

      // Resets explorer filters
      this.$store.commit('explorer/SET_DESTINATION_MAP_ROUTES_FILTERS', {});
      this.$store.commit('explorer/SET_DESTINATION_MAP_EVENTS_FILTERS', {});
      this.$store.commit('explorer/SET_DESTINATION_MAP_PROVIDERS_FILTERS', {});
      this.$store.commit('explorer/SET_EXPLORER_FORCE_CALLS', true);

      // Pushes to the constructed URL
      const route: any = this.localePath(`${payload}${sportsQuerys}`);
      this.$router.push(route);

      // Closes modal and resets some datas
      this.$store.commit('SET_GLOBALSEARCH_DISPLAY', false);
      this.$root.$emit('remove-selected-area-button');
      if (this.selectedDestination === this.$t('globalSearch.aroundMeButton')) {
        this.$nuxt.$emit('searches-around-me');
      }
    }

    // If active tab is Destination Tab, we don't search, but display the
    // Sport Tab instead
    if (this.globalSearchTab === this.Tabs.DESTINATION) {
      this.$store.commit('explorer/SET_GLOBAL_SEARCH_TAB', this.Tabs.SPORT);
    }
  }

  /* In beforeDestroy, we will reset every status and datas that were declared */
  beforeDestroy () {
    this.selectedDestination = {};
    this.$root.$emit('reset-sports');
    if (process.client) document.body.classList.remove('overflow-hidden');
    this.specialContextDisplay = false;
    this.$store.commit('explorer/SET_GLOBAL_SEARCH_TAB', this.Tabs.DESTINATION);
  }
}
